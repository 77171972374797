import React, { useState } from 'react';
import { validate, format } from "rut.js";
import axios from "axios";
import './AccounType.css';
import Button from '@mui/material/Button';
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

const AccounType = (props) => {
  const [errors, setErrors] = useState({
    rut: "",
    accountType: "",
  });

  const [data, setData] = useState({});
  const [desabled, setDesabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [AlertActivity, setAlertActivity] = useState(false);

  const handleType = (e) => {
    props.setAccountType(e.target.value);
  };

  const handleData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const valideForm = () => {
    let errorsForm = {}
    const rutAccount = format(data.rut, { dots: false });
    if (props.accountType === "persona") {
      if (!validate(rutAccount) || rutAccount.substring(0, rutAccount.length - 2) > 50000000) {
        errorsForm.rut = "Este RUT no es valido para una cuenta persona";
      }
    }

    if (props.accountType === "empresa") {
      if (!validate(rutAccount) || rutAccount.substring(0, rutAccount.length - 2) < 50000000) {
        errorsForm.rut = "Este RUT no es valido para una cuenta empresa";
      }
    }

    if (rutAccount === "-") {
      errorsForm.rut = "Debe ingresar un RUT";
    }

    if (props.accountType === "") {
      errorsForm.accountType = "Debe seleccionar un tipo de cuenta";
    }

    setErrors({ ...errorsForm });
    return Object.keys(errorsForm).length === 0 ? true : false;
  }

  const handleUser = async () => {
    let accountTypeView = 0;
    const rutAccount = format(data.rut, { dots: false });

    if (valideForm()) {
      props.setOpen(true);

      let bodyFormData = new FormData();

      bodyFormData.append('operability', process.env.REACT_APP_LIBREDT);
      bodyFormData.append('parameter', rutAccount);
      bodyFormData.append('option', 'situacion-tributaria');

      try {
        const res = await axios.post(`${props.config.url}/servicios/validrut`, { rut: rutAccount, access_key: process.env.REACT_APP_access_key }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.config.token}`,
          },
        })

        if (res.data.status === "failed") {
          props.setOpen(false);
          setDesabled(true)
          setOpen(true)
          return
        }
        const response = await axios.post(`${props.config.url}/servicios/getlibredte`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${props.config.token}`,
          },
        })

        if (response.data.code || response.data["inicio_actividade"] === null) {
          props.setOpenAlertConnect(true);
        } else {
          props.setClient(response.data);
          props.setStep(3);
          if (response.data.rut) {
            if (response.data.inicio_actividades === false) {
              accountTypeView = 0;
              props.setClient({
                rut: response.data.rut + "-" + response.data.dv,
                accountClientType: 0,
                activityCode: "960909",
                activityName: "OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P."
              });

            } else if (response.data.rut < 50000000) {
              accountTypeView = 1;
              props.setClient({
                rut: response.data.rut + "-" + response.data.dv,
                razonSocial: response.data["razon_social"],
                accountClientType: 1,
                activityList: response.data.actividades
              });

            } else {
              accountTypeView = 2;
              props.setClient({
                rut: response.data.rut + "-" + response.data.dv,
                razonSocial: response.data["razon_social"],
                accountClientType: 2,
                activityList: response.data.actividades,
              });

            } if (response.data.inicio_actividades === false && response.data.rut > 50000000) {
              props.setOpen(false);
              setAlertActivity(true);
              setDesabled(true)
              // console.log('rut empresa', response.data.inicio_actividades)
              return

            }

            if (accountTypeView === 0) {
              props.goToStep(4);
            } else if (accountTypeView === 1) {
              props.goToStep(3);
            } else if (accountTypeView === 2) {
              props.goToStep(5);
            } //else {
            //   props.goToStep(6);
            // }
          }
        }
      } catch (e) {
        // console.error(e);
      } finally {
        //console.log('We do cleanup here');
      }
      props.setOpen(false);
    }
  };

  return (
    <div className="form-AccouType">
      <h2>Seleccione tipo de cuenta</h2>
      <div className="p-div-AccounType">
        <div className="radio-toolbar">
          <input
            type="radio"
            id="radioPersona"
            name="tipocuenta"
            value="persona"
            onClick={handleType}
          />

          <label htmlFor="radioPersona">
            <AccessibilityNewIcon fontSize="large" />
            Persona
          </label>
          <input
            type="radio"
            id="radioEmpresa"
            name="tipocuenta"
            value="empresa"
            onClick={handleType}
          />
          <label htmlFor="radioEmpresa">
            <BusinessCenterIcon fontSize="large" />
            Empresa
          </label>
          <div className="error-div">
            {errors.accountType ? (
              <p className="p-error-AccounType">{errors.accountType}</p>
            ) : (
              ""
            )}
          </div>

        </div>
        <div className="p-input-section">
          <p className="p-payku-AccounType p-padding">
            Ingrese RUT sin puntos y sin guiones.
          </p>
          <TextField
            className="p-input-AccounType"
            helperText={errors.rut}
            error={errors.rut ? true : false}
            inputProps={{ maxLength: 10 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            id="standard-rut"
            label="RUT"
            variant="standard"
            name="rut"
            onChange={handleData}
          />
        </div>
      </div>
      <div className="btn-section-AccounType btn-two">
        <Button className="btn-AccounType-Volver" variant="outlined"
          onClick={() => {
            props.setStep(1);
            props.previousStep();
            setErrors({
              rut: "",
              accountType: ""
            })
          }}>Volver
        </Button>
        <Button
          className="btn-AccounType-Siguiente"
          variant="contained"
          disabled={desabled}
          onClick={handleUser}>Siguiente
        </Button>
      </div>
      <div className="Dialog-Section">
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor, comuníquese a través de nuestro chat con el equipo de soporte.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="boton_block" target="_self" href="https://payku.cl/">Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>

      <Dialog
        open={AlertActivity}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Empresa no posee inicio de actividades. Por favor, comuníquese a través de nuestro chat con el equipo de soporte.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="boton_block" target="_self" href="https://payku.cl/">Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}


export default AccounType;