import React from 'react';
import './Welcome.css'
import logo_redCompra from '../../img/logo_redCompra.png';
import logo_fpay from '../../img/logo_fpay.png';
import logo_mach from '../../img/logo_mach.png';
import logo_check from '../../img/logo_check.png';
import logo_khipu from '../../img/logo_khipu.png';
import logo_hites from '../../img/logo_hites.png';
import logo_klap_efectivo from '../../img/logo_klap_efectivo.png';
import logo_klap_transferencia from '../../img/logo_klap_transferencia.png';
import logo_pago46 from '../../img/logo_pago46.png';
import logo_cleo from '../../img/logo_cleo.png';
import logo_servifacil from '../../img/logo_servifacil.png';
import Button from '@mui/material/Button';

function Welcome(props) {
    return (
        <div className="form-Welcome">
            <h2>¡Bienvenido a Payku!</h2>
            <div className="p-div-Welcome">
                <p className="p-payku-welcome">
                    Payku nace por las ganas de crear un emprendimiento que facilite el
                    día a día a todo el comercio electrónico desde emprendimientos
                    personales, tiendas pequeñas, startups, y grandes empresas. Es
                    nuestra convicción construir una infraestructura de pagos adecuada a
                    ti y a tu negocio.
                </p>

                <div className="img-container-welcome">
                    <img
                        className="img-logo-welcome"
                        src={logo_mach}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_check}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_khipu}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_hites}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_klap_efectivo}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_klap_transferencia}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_pago46}
                        alt="logo"
                    />

                    <img
                        className="img-logo-welcome"
                        src={logo_cleo}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_servifacil}
                        alt="logo"
                    />
                    <img
                        className="img-logo-welcome"
                        src={logo_redCompra}
                        alt="logo"
                    />
                </div>

                <p className="p-payku-welcome">
                    PD: Si necesitas ayuda puedes hablar con nuestro chat de soporte
                    haciendo click aquí o puedes escribirnos a hola@payku.cl
                </p>
            </div>

            <div className="btn-section-Welcome">
                <Button className="btn-welcome" variant="contained" onClick={() => {
                    props.setStep(2)
                    props.nextStep()
                }}>Siguiente</Button>
            </div>
        </div>
    );
}

export default Welcome;