import './App.css';
import OnBoarding from './Components/OnBoarding';
import {
    Routes,
    Route
} from "react-router-dom";

function App() {
    return (
    <>
        <Routes >
        <Route exact path = '/v2'
        element = { < OnBoarding / > }/>
        </Routes>
    </>
    );
}

export default App;