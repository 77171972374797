import React from 'react';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


root.render(
<React.StrictMode>
        <Router>
            <StrictMode>
            <App/>
            </StrictMode>
        </Router>
</React.StrictMode>
);