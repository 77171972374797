import React, { useState, useEffect } from "react";
import axios from "axios";
import stringSimilarity from "string-similarity";
import "./BusinessVerification.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

const BusinessVerification = (props) => {
  const [activity, setActivity] = useState({
    code: "",
    serial: "",
  });
  const [rubros, setRubros] = useState([])
  const [alertRubro, setAlertRubro] = useState(false)
  const [robroCode, setRubroCode] = useState('')
  const [desabled, setDesabled] = useState(false);


  const [errors, setErrors] = useState({
    serialError: "",
    activityError: "",
  });



  const consultRubro = async (item) => {
    let activityCode = item?.split("-");
    const rubro = await axios.post(process.env.REACT_APP_RUBRO, {
      "access_key": process.env.REACT_APP_access_key,
      "rubro_codigo": activityCode[0]
    })

    if (rubro.data.status === "success") {
      setRubros([rubro.data.rubro])
    } else if (rubro.data.status === "failed") {
      setShow(false)
      setAlertRubro(true)
      setDesabled(true)
    }
  }
  const [show, setShow] = useState(false)
  const handleActivity = (e, text) => {
    setActivity({ ...activity, [e.target.name]: e.target.value });
    if (text === "isSelect") {
      setShow(true)
      consultRubro(e.target.value);
    }

  };

  useEffect(() => {
    if (activity.code && activity.codeRubro && activity.serial !== "" && errors.serialError === "" && errors.activityError === "") {
      setDesabled(false)
    }
  }, [activity])

  const handleCode = (rubro) => {
    setRubroCode(rubro.mcccode)
  };

  const valideProd = props?.config?.httpRef?.includes("onboarding");

  const valideForm = () => {
    let errorsForm = {};

    const rutSerial = activity.serial;

    if (rutSerial === "") {
      errorsForm.serialError = "Debe ingresar un serial E-RUT";
    }

    if (activity.code === "") {
      errorsForm.activityError = "Debe elegir una actividad";
    }
    if (robroCode === "" && show === true) {
      errorsForm.rubroError = "Debe elegir un rubro";
    }

    setErrors({ ...errorsForm });

    return Object.keys(errorsForm).length === 0 ? true : false;
  };


  const verifyRut = async () => {
    let errorsForm = {};
    const rutSerial = activity.serial;
    if (valideForm()) {
      props.setOpen(true);

      let bodyFormData = new FormData();

      bodyFormData.append("operability", process.env.REACT_APP_LIBREDT);
      bodyFormData.append("parameter", rutSerial);
      bodyFormData.append("option", "verificar-rut");

      await axios
        .post(`${props.config.url}/servicios/getlibredte`, bodyFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${props.config.token}`,
          },
        })
        .then(async function (response) {
          if (response.data.code === 400) {
            setErrors({
              ...errorsForm,
              serialError: "El numero de serial no es válido.",
            });
          } else {
            // let comparativeRating = stringSimilarity.compareTwoStrings(
            //   props.client.razonSocial,
            //   response.data["razon_social"]
            // );

            // if (comparativeRating >= 0.8) {
            // } else {
            //   setErrors({
            //     ...errorsForm,
            //     serialError: "El numero de serial no corresponde a la empresa.",
            //   });
            // }
            let activityArray = activity.code.split("-");
            let activityRubro = activity.codeRubro.split("-");

            await axios
              .post(
                `${props.config.url}/api/registeraccountv2`,
                {
                  user: props.config.user,
                  rut: props.client.rut,
                  e_rut: rutSerial,
                  merchant_account: props.client.accountClientType,
                  merchant_name: props.client.razonSocial || null,
                  merchant_giro: activityArray[1],
                  co_actividad: robroCode,
                  rubd_id: activityRubro[0],
                  merchant_typedocument: 2,
                  wallet: 2,
                  ratepayment: props.ratePayment,
                  merchant_services: activityArray[0],
                  region: props.client.id,
                  address: props.client.address,
                  commune: props.client.commune,
                  nlocal: props.client.nlocal,
                  nstreet: props.client.nstreet

                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${props.config.token}`,
                  },
                }
              )
              .then(function (response) {
                props.setClient({
                  ...props.client,
                  idAccount: response.data.id,
                });
                if (response.data.error) {
                  props.setOpenAlertBlock(true);
                  setTimeout(() => {
                    window.top.location.href = `${props.config.url}/index/iniciarsesion`;
                  }, 5000);
                } else {
                  props.setStep(5);
                  props.goToStep(8);
                }
              })
              .catch(function (error) {
                window.top.location.href = `${props.config.url}/index/iniciarsesion`;
              });
          }
        })
        .catch(function (error) {
          //console.log(error);
        });

      props.setOpen(false);

      if (valideProd && valideForm()) {
        props.setStep(5);
        props.goToStep(8);
      }
      //  else {
      //    props.goToStep(5);
      //  }
    }
  };

  return (
    <div className="form-payku-BusinessVerification">
      <h2>Verificación de empresa</h2>
      <div className="p-div-BusinessVerification">
        <div className="p-input-section-BusinessVerification">
          <p className="p-payku-BusinessVerification p-padding">
            RUT Empresa: {props.client.rut}
          </p>
          <FormControl
            variant="standard"
            className="p-input-BusinessVerification"
            error={errors.activityError ? true : false}
          >
            <InputLabel id="demo-simple-select-standard-label">
              Actividad económica
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              name="code"
              defaultValue={activity}
              label="Actividad economica"
              onChange={(e) => handleActivity(e, 'isSelect')}
            >
              {props.client &&
                props.client.activityList &&
                props.client.activityList.map((activity) => (
                  <MenuItem
                    key={activity.codigo}
                    value={`${activity.codigo}-${activity.glosa}`}
                  >
                    {activity.glosa}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{errors.activityError}</FormHelperText>
          </FormControl>
        </div>

        <div className="p-input-section-BusinessVerification">
          {show &&
            <FormControl
              variant="standard"
              className="p-input-BusinessVerification"
              error={errors.rubroError ? true : false}
            >
              <InputLabel id="demo-simple-select-standard-label">
                Rubro
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="codeRubro"
                defaultValue={rubros.description || activity.codeRubro}
                label="Actividad economica"
                onChange={(e) => handleActivity(e)}
              >
                {
                  rubros?.map((rubro) => (
                    <MenuItem
                      key={rubro.id}
                      value={`${rubro.id}-${rubro.description}`}
                      onClick={() => handleCode(rubro)}
                    >
                      {rubro.description}
                    </MenuItem>
                  ))}

              </Select>
              <FormHelperText>{errors.rubroError}</FormHelperText>
            </FormControl>
          }
        </div>
        <div className="p-input-section-BusinessVerification">
          <p className="p-payku-BusinessVerification p-padding">
            Ingresa tu numero de serie de E-RUT.
          </p>
          <TextField
            className="p-input-BusinessVerification"
            name="serial"
            helperText={errors.serialError}
            error={errors.serialError ? true : false}
            inputProps={{ maxLength: 14 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            id="standard-rut"
            label="E-RUT"
            variant="standard"
            onChange={(e) => handleActivity(e, 'isInput')}
          />
        </div>
      </div>
      <div className="btn-section-BusinessVerification">
        <Button
          className="btn-BusinessVerification-Volver"
          onClick={() => {
            props.setStep(2);
            props.goToStep(2);
            setErrors({
              serialError: "",
              activityError: "",
            });
          }}
        >
          Volver
        </Button>
        <Button
          className="btn-BusinessVerification-Siguiente"
          disabled={desabled}
          onClick={verifyRut}
        >
          Siguiente
        </Button>
      </div>
      <div>
        <Dialog
          open={alertRubro}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Actividad no posee rubro asignado. Por favor, intente más tarde.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="boton_rubro"
              onClick={() => setAlertRubro(false)}
            >Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default BusinessVerification;
