import React from 'react';
import './Dashboard.css'
import CryptoJS from "crypto-js";
import logo_redCompra from '../../img/logo_redCompra.png';
import logo_fpay from '../../img/logo_fpay.png';
import logo_mach from '../../img/logo_mach.png';
import logo_check from '../../img/logo_check.png';
import logo_khipu from '../../img/logo_khipu.png';
import logo_hites from '../../img/logo_hites.png';
import logo_klap_efectivo from '../../img/logo_klap_efectivo.png';
import logo_klap_transferencia from '../../img/logo_klap_transferencia.png';
import logo_pago46 from '../../img/logo_pago46.png';
import logo_cleo from '../../img/logo_cleo.png';
import logo_servifacil from '../../img/logo_servifacil.png';
import Button from '@mui/material/Button';


const Dashboard = (props) => {
  const redirectDashboard = () => {
    document.getElementById('dashboardRedirect').submit();
  }
  return (
    <div className="form-payku-Dashboard">
      <form action={`${props.config?.url}/index/identifyAccount`} method="post" id="dashboardRedirect">
        <input type="hidden" name="account" value={props.client.idAccount} />
        <input type="hidden" name="valid" value={CryptoJS.HmacSHA256(props.client.idAccount, "pmM2fa5CLOp4HbMYpeqe")} />
      </form>
      <h2>Gracias por registrarte</h2>
      <div className="p-div-Dashboard">
        <p className="p-payku-Dashboard">
          Tu cuenta ha sido creada exitosamente, tus pagos seran depositados en el wallet payku, los
          medios de pago y donde seran depositados los pagos realizados a tu
          cuenta pueden ser cambiados en la configuracion de tu dashboard.
        </p>

        <div className="img-container-Dashboard">
 
          <img
            className="img-logo-dashboard"
            src={logo_mach}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_check}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_khipu}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_hites}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_klap_efectivo}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_klap_transferencia}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_pago46}
            alt="logo"
          />

          <img
            className="img-logo-dashboard"
            src={logo_cleo}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_servifacil}
            alt="logo"
          />
          <img
            className="img-logo-dashboard"
            src={logo_redCompra}
            alt="logo"
          />
        </div>

        <p className="p-payku-Dashboard">
          Para redirigirte a tu dashboard solo has click en el boton Dashboard.
        </p>
      </div>
      <div className="btn-section-Dashboard">
        <Button className="btn-Dashboard"
          variant="contained"
          onClick={redirectDashboard}
        >Dashboard</Button>
      </div>
    </div>
  );
}

export default Dashboard;

