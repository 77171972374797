import React, { useState } from "react";
import "./PersonalInfo.css";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import PersonIcon from "@mui/icons-material/Person";
import Button from "@mui/material/Button";

const PersonalInfo = (props) => {
  const [info, setInfo] = useState({
    name: "",
    lastName: "",
  });
  const [errors, setErrors] = useState({
    nameError: "",
    lastNameError: "",
  });

  const handleInfo = (e) => {
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const valideProd = props?.config?.httpRef?.includes("onboarding");

  const valideForm = () => {
    let errorsForm = {};

    if (info.name.length < 3) {
      errorsForm.nameError = "Debe contener al menos 3 caracteres";
    }

    if (!info.name.match(/^[a-zA-Z\s]*$/)) {
      errorsForm.nameError = "Solo debe contener letras";
    }

    if (info.name === "") {
      errorsForm.nameError = "Debe ingresar su nombre";
    }

    if (info.lastName.length < 3) {
      errorsForm.lastNameError = "Debe contener al menos 3 caracteres";
    }

    if (!info.lastName.match(/^[a-zA-Z\s]*$/)) {
      errorsForm.lastNameError = "Solo debe contener letras";
    }

    if (info.lastName === "") {
      errorsForm.lastNameError = "Debe ingresar su apellido";
    }

    setErrors(errorsForm);

    return Object.keys(errorsForm).length === 0 ? true : false;
  };

  const addInfo = async () => {
    if (valideForm()) {
      props.setClient({
        ...props.client,
        name: info.name,
        lastName: info.lastName,
      });
      /**
        * -------------------------------
        * esta seccion del codigo esta en version de prueba. Aun no esta operativo!
        
     if(!valideProd){
      
       await axios
       .post(
         `${props.config.url}/api/registeraccountv2`,
         {
           user: props.config.user,
           name: 'prueba',
           lastName: 'prueba',
           rut: props.client.rut,
           merchant_account: props.client.accountClientType,
           merchant_name: props.client.razonSocial || null,
           merchant_giro: props.client.activityName,
           merchant_typedocument: 1,
           wallet: 2,
           ratepayment: props.ratePayment,
           merchant_services: props.client.activityCode,
           compliance_selfieurl: "https://storage.googleapis.com/storage-payku-prd/public/compliance/docs/us89-d78d199b-2c83-4064-b35d-a49013394881.png",
           compliance_carnetanversourl: "https://storage.googleapis.com/storage-payku-prd/public/compliance/docs/us89-c25bbb1b-8454-4a9e-b564-2add3a0e5b1b.png",
           compliance_carnetreversourl: "https://storage.googleapis.com/storage-payku-prd/public/compliance/docs/us89-47606f0c-9c7b-4775-93c2-4ebfcf816124.png",
           //compliance_documentaddressurl: urlList.compliance_documentaddressurl
         }, 
       
         {
           headers: {
             "Content-Type": "application/json",
             Authorization: `Bearer ${props.config.token}`,
           },
         }
       ) 
       .then(function (response) {
         props.setClient({
           ...props.client,
           idAccount: response.data.id,
         })
        })
     }
     /**
       *
       * hasta aqui el codigo de version de prueba. Solo falta ver sonde se llena las variables de Name y Lastname para que sea dinamico
       * 
       * 
       *  */
      props.goToStep(5);
      if (valideProd) {
        props.setStep(5);
        props.goToStep(7);
      }

      else {
      }
    }
  };

  return (
    <div className="form-payku-PersonalInfo">
      <h2>Información Personal</h2>
      <div className="p-div-PersonalInfo">
        <div className="p-input-section-PersonalInfo">
          <p className="p-payku-PersonalInfo p-padding">Ingresa tu nombre.</p>
          <TextField
            className="p-input-PersonalInfo"
            name="name"
            autoComplete="off"
            helperText={errors.nameError}
            error={errors.nameError ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            id="standard-rut"
            label="Nombre"
            variant="standard"
            onChange={handleInfo}
          />
        </div>
        <div className="p-input-section-PersonalInfo">
          <p className="p-payku-PersonalInfo p-padding">Ingresa tu apellido.</p>
          <TextField
            className="p-input-PersonalInfo"
            name="lastName"
            autoComplete="off"
            helperText={errors.lastNameError}
            error={errors.lastNameError ? true : false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            id="standard-rut"
            label="Apellido"
            variant="standard"
            onChange={handleInfo}
          />
        </div>
      </div>
      <div className="btn-section-PersonalInfo">
        <Button
          className="btn-PersonalInfo-Volver"
          onClick={() => {
            props.setStep(2);
            props.goToStep(2);
            setErrors({
              nameError: "",
              lastNameError: "",
            });
          }}
        >
          Volver
        </Button>
        <Button className="btn-PersonalInfo-Siguiente" onClick={addInfo}>
          Siguiente
        </Button>
      </div>
    </div>
  );
};

export default PersonalInfo;
