import React, { useState, useEffect } from "react";
import "./infoAddress.css"
import axios from "axios";
import { Autocomplete, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'



function InfoAddress(props) {
    const [comuna, setComuna] = useState([]);
    const [inputDireccion, setInputDireccion] = useState('');
    const [value, setValue] = useState(null);
    const [errors, setErrors] = useState({
        errorComuna: "",
        errorDireccion: "",
        errorCalle: "",
        errorLocal: ""
    });
    const [inputCalle, setInputCalle] = useState('');
    const [inputLocal, setInputLocal] = useState('');
    const infoComuna = {
        options: comuna,
        getOptionLabel: (option) => option?.name
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.post(process.env.REACT_APP_COMUNA, {
                    "access_key": process.env.REACT_APP_access_key,
                });
                setComuna(response.data.commune)
            } catch (error) {
                // console.log(error.message);
            }
        })();
    }, []);


    const handleDirecion = (event) => {
        setInputDireccion(event.target.value);
    };
    const handleCalle = (event) => {
        setInputCalle(event.target.value);

    };
    const handleLocal = (event) => {
        setInputLocal(event.target.value);

    };
    const addInfo = async () => {
        if (valideForm()) {
            props.setClient({
                ...props.client,
                id: value.region,
                commune: value.id,
                nstreet: inputCalle,
                nlocal: inputLocal,
                address: inputDireccion
            });
            if (props.accountType === "persona") {
                props.goToStep(6);
            } else if (props.accountType === "empresa") {
                props.goToStep(7);
            }
        }
    }

    const valideForm = () => {
        let errorsForm = {};
        if (value === null) {
            errorsForm.errorComuna = "Debe seleccionar una comuna";
        }
        if (inputDireccion === "") {
            errorsForm.errorDireccion = "Debe ingresar una dirección";
        }
        if (inputCalle === "") {
            errorsForm.errorCalle = "Debe ingresar número de Calle";
        }
        if (inputLocal === "") {
            errorsForm.errorLocal = "Debe ingresar número de Local";
        }

        setErrors(errorsForm);

        return Object.keys(errorsForm).length === 0 ? true : false;
    };

    return (
        <div className="form-payku-InfoAddress">
            <h2>Ingrese su Dirección</h2>
            <div className="p-div-InfoAddress">
                <div className="p-input-section-InfoAddress">
                    <FormControl fullWidth >
                        <Autocomplete
                            {...infoComuna}
                            id=""
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label="Comuna" variant="standard"
                                    helperText={errors.errorComuna}
                                    autoComplete="off"
                                    error={errors.errorComuna ? true : false}
                                />
                            )}
                        />
                    </FormControl>
                </div>
                <div className="p-input-section-InfoAddress">
                    <TextField
                        className="p-input-InfoAddress"
                        name="Dirección"
                        id="standard-Dirección"
                        label="Nombre de Calle"
                        autoComplete="off"
                        variant="standard"
                        placeholder="Av. nombre de calle"
                        value={inputDireccion}
                        helperText={errors.errorDireccion}
                        error={errors.errorDireccion ? true : false}
                        onChange={(event) => handleDirecion(event)}
                    />
                </div>
                <div className="p-input-section-InfoAddress">
                    <TextField
                        className="p-input-InfoAddress"
                        name="Calle"
                        autoComplete="off"
                        type="text"
                        id="standard-calle"
                        label="Número de Calle"
                        variant="standard"
                        placeholder="1234"
                        value={inputCalle}
                        helperText={errors.errorCalle}
                        error={errors.errorCalle ? true : false}
                        onChange={(event) => handleCalle(event)}
                    />
                </div>
                <div className="p-input-section-InfoAddress">
                    <TextField
                        className="p-input-InfoAddress"
                        name="local"
                        autoComplete="off"
                        type="text"
                        id="standard-local"
                        label="Número de Dpto / Casa / Ofc / Local"
                        variant="standard"
                        placeholder="12A"
                        value={inputLocal}
                        helperText={errors.errorLocal}
                        error={errors.errorLocal ? true : false}
                        onChange={(event) => handleLocal(event)}
                    />
                </div>
            </div>
            <div className="btn-section-InfoAddress">
                <Button
                    className="btn-InfoAddress-Volver"
                    onClick={() => {
                        props.setStep(2);
                        props.goToStep(2);
                        setErrors({
                            errorDireccion: "",
                            errorCalle: "",
                            errorLocal: ""
                        });
                    }}
                >
                    Volver
                </Button>
                <Button className="btn-InfoAddress-Siguiente"
                    onClick={addInfo}
                >
                    Siguiente
                </Button>
            </div>
        </div>
    )
}

export default InfoAddress
