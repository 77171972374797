import React from 'react'; 
import logo_payku from '../../img/logo_payku.png';
import './navBar.css'

const NavBar = () => {
    return (
        <img className="logo-payku"
          src={logo_payku}
          alt="logo"
      />
    )
}


export default NavBar