import React, { useState, useEffect } from "react";
import './PersonType.css';
import axios from "axios";
import StorefrontIcon from '@mui/icons-material/Storefront';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

const PersonType = (props) => {
  const [activity, setActivity] = useState({
    code: ""
  })
  const [accountType, setAccountType] = useState("");
  const [errors, setErrors] = useState({
    activity: "",
    accountType: "",
  });
  const [show, setShow] = useState(false)
  const handleType = (e) => {
    setAccountType(e.target.value);
  };
  const [rubros, setRubros] = useState([])
  const [rubroCode, setRubroCode] = useState('')
  const [alertRubro, setAlertRubro] = useState(false)
  const [desabled, setDesabled] = useState(false);



  const consultRubro = async (item) => {
    let activityCode = item?.split("-");
    const rubro = await axios.post(process.env.REACT_APP_RUBRO, {
      "access_key": process.env.REACT_APP_access_key,
      "rubro_codigo": activityCode[0]
    })
    if (rubro.data.status === "success") {
      setRubros([rubro.data.rubro])
    } else if (rubro.data.status === "failed") {
      setShow(false)
      setAlertRubro(true)
      setDesabled(true)

    }

  }

  useEffect(() => {
    if (activity.code && activity.codeRubro && activity.serial !== "" && errors.serialError === "" && errors.activityError === "") {
      setDesabled(false)
    }
  }, [activity])


  const handleActivity = (e, text) => {
    setActivity({ ...activity, [e.target.name]: e.target.value });
    if (text === "isSelect") {
      setShow(true)
      consultRubro(e.target.value);
    }
  };
  const handleCode = (rubro) => {
    setRubroCode(rubro.mcccode)
  };
  const valideForm = () => {
    let errorsForm = {}
    if (accountType === "") {
      errorsForm.accountType = "Debe seleccionar un tipo de cuenta";
    }
    if (accountType === "comercial") {
      if (activity.code === "") {
        errorsForm.activityError = "Debe elegir una actividad";
      }
    }
    if (rubroCode === "" && show === true) {
      errorsForm.rubroError = "Debe elegir un rubro";
    }

    setErrors({ ...errorsForm });
    return Object.keys(errorsForm).length === 0 ? true : false;
  }
  const handleUser = () => {
    if (valideForm()) {
      if (accountType === "natural") {
        props.setClient({
          ...props.client,
          accountClientType: 0,
          activityCode: "960909",
          activityName: "OTRAS ACTIVIDADES DE SERVICIOS PERSONALES N.C.P."
        })
      } else {
        let activityArray = activity.code.split("-");
        let activityRubro = activity.codeRubro.split("-");
        props.setClient({
          ...props.client,
          accountClientType: 1,
          activityCode: activityArray[0],
          activityName: activityArray[1],
          co_actividad: rubroCode,
          rubd_id: activityRubro[0],
        })

      }
      props.setStep(4);
      props.goToStep(4);
    }
  };
  return (
    <div className="form-payku-PersonType">
      <h2>Seleccione tipo de cuenta persona</h2>
      <div className="p-div-PersonType">
        <div className="radio-toolbar-PersonType">
          <input
            type="radio"
            id="radioNatural"
            name="tipocuenta"
            value="natural"
            onClick={handleType}
          />
          <label htmlFor="radioNatural">
            <AccessibilityIcon fontSize="large" /> Natural
          </label>
          <input
            type="radio"
            id="radioComercial"
            name="tipocuenta"
            value="comercial"
            onClick={handleType}
          />
          <label htmlFor="radioComercial">
            <StorefrontIcon fontSize="large" />
            Comercial
          </label>
          <div className="error-div-PersonType">
            {errors.accountType ? (
              <p className="p-error">{errors.accountType}</p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="p-input-section-PersonType fix-section">
          {accountType === "comercial" ? (
            <>
              <p className="p-payku p-padding">
                RUT: {props.client.rut}
              </p>
              <FormControl variant="standard" className="p-input-PersonType"
                error={errors.activityError ? true : false}>
                <InputLabel id="demo-simple-select-standard-label">Actividad economica</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  name="code"
                  value={activity.code}
                  label="Actividad economica"
                  onChange={(e) => handleActivity(e, 'isSelect')}
                >
                  {props.client && props.client.activityList && props.client.activityList.map((activity) => (
                    <MenuItem value={`${activity.codigo}-${activity.glosa}`}>{activity.glosa}</MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.activityError}</FormHelperText>
              </FormControl>
            </>
          ) : (
            null
          )
          }
        </div>
        <div className="p-input-section-PersonType fix-section">
          {show && accountType === "comercial" ? (
            <FormControl
              variant="standard"
              className="p-input-PersonType"
              error={errors.rubroError ? true : false}
            >
              <InputLabel id="demo-simple-select-standard-labe">
                Rubro
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                name="codeRubro"
                defaultValue={rubros.description || activity.codeRubro}
                label="Rubro"
                onChange={(e) => handleActivity(e)}
              >
                {
                  rubros?.map((rubro) => (
                    <MenuItem
                      key={rubro.id}
                      value={`${rubro.id}-${rubro.description}`}
                      onClick={() => handleCode(rubro)}
                    >
                      {rubro.description}
                    </MenuItem>
                  ))}

              </Select>
              <FormHelperText>{errors.rubroError}</FormHelperText>
            </FormControl>
          ) : (
            null
          )
          }
        </div>
      </div>
      <div className="btn-section-PersonType">
        <Button
          className="btn-PersonType-Volver"
          onClick={() => {
            props.setStep(1);
            props.previousStep();
            setErrors({
              rut: "",
              accountType: ""
            })
          }}
        >
          <span>Volver</span>
        </Button>
        <Button
          className="btn-PersonType-Siguiente"
          disabled={desabled}
          onClick={handleUser}>
          <span>Siguiente</span>
        </Button>
      </div>
      <div>
        <Dialog
          open={alertRubro}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Actividad nsfnvnsjnv. Por favor, intente más tarde.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="boton_rubro"
              onClick={() => setAlertRubro(false)}
            >Aceptar</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
export default PersonType