import React, { useState } from "react";
import axios from "axios";
import "./ImagePerson.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
const CryptoJS = require("crypto-js");

const ImagePerson = (props) => {
  const [files, setFiles] = useState(["", "", ""]);
  const [errors, setErrors] = useState([]);
  const [errorUpload, setErrorUpload] = useState(false);
  const [errorDontUpload, setErrorDontUpload] = useState(false);

  const handleFile = (e) => {
    let newFiles = [...files];
    newFiles[e.target.id] = e.target.files[0];
    setFiles(newFiles);
  };

  const stepBack = () => {
    if (props.client.accountClientType === 0) {
      props.setStep(2);
      props.goToStep(2);
    } else {
      props.setStep(3);
      props.goToStep(3);
    }
    setErrors([]);
  };

  const imageVerify = async () => {
    let errors = [];
    let urlList = {};

    let extFile = "";
    files.forEach((file, index) => {
      if (file) {
        extFile = file.name.split(".").pop();
      }
      if (!file) {
        errors[index] = "Debe adjuntar el archivo solicitado";
      } else if (!["jpeg", "png", "jpg", "webp"].includes(extFile)) {
        errors[index] = "El formato del archivo debe ser jpeg, png, jpg o webp";
      } else if (file.size > 10000000) {
        errors[index] = "Archivo debe ser menor de 10mb";
      }
    });

    setErrors(errors);

    const noErrors = errors.length === 0;

    if (noErrors) {
      if (files) {
        let formData = new FormData();
        files.forEach((file) => {
          formData.append("file", file);
        });

        const userId = props.config.user.slice(0, 4);
        formData.append("user", props.config.user);
        const sign = CryptoJS.HmacSHA256(
          userId,
          process.env.REACT_APP_UPLOAD_TOKEN
        ).toString();

        formData.append("valid", sign);
        props.setOpen(true);
        await axios({
          url: `${props.config.onBoarding}/ut6x7xbRWHMdXm8uKM80u9dyY1crxC`,
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          data: formData,
        })
          .then(async (res) => {
            urlList = res.data.url;

            const accountPerson = (props?.client?.accountClientType === 1) ? 2 : 1;

            await axios
              .post(
                `${props.config.url}/api/registeraccountv2`,
                {
                  user: props.config.user,
                  name: props.client.name || null,
                  lastName: props.client.lastName || null,
                  rut: props.client.rut,
                  merchant_account: props.client.accountClientType,
                  merchant_name: props.client.razonSocial || null,
                  merchant_giro: props.client.activityName,
                  merchant_typedocument: accountPerson,
                  wallet: 2,
                  ratepayment: props.ratePayment,
                  merchant_services: props.client.activityCode,
                  compliance_selfieurl: urlList.compliance_selfieurl || null,
                  compliance_carnetanversourl:
                    urlList.compliance_carnetanversourl || null,
                  compliance_carnetreversourl:
                    urlList.compliance_carnetreversourl || null,
                  region: props.client.id,
                  address: props.client.address,
                  commune: props.client.commune,
                  nlocal: props.client.nlocal,
                  nstreet: props.client.nstreet,
                  rubd_id: props.client.rubd_id,
                  co_actividad: props.client.co_actividad
                  //compliance_documentaddressurl: urlList.compliance_documentaddressurl
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${props.config.token}`,
                  },
                }
              ).then(function (response) {
                props.setClient({
                  ...props.client,
                  idAccount: response.data.id,
                });

                if (response.data.error) {
                  props.setOpen(false);
                  props.setOpenAlertBlock(true);
                  setTimeout(() => {
                    // window.top.location.href = `${props.config.url}/index/iniciarsesion`;
                  }, 5000);
                } else {
                  // setErrorDontUpload(false);
                  props.setStep(5);
                  props.goToStep(7);
                }
              });

            props.setOpen(false);
          })
          .catch(function (error) {
            setErrorDontUpload(true);
            props.setOpen(false);
            setErrorUpload(true)
            // console.log(
            //   "Hubo un problema con la petición Fetch: "
            // );
            return
          });
      }
      // console.log('errorDontUpload', errorDontUpload)
      if (errorDontUpload === false) {
        props.setStep(5);
        props.goToStep(8);
      }
    }
  };
  return (
    <>
      <div className="form-payku-ImagePerson">
        <h2>Adjunte documentos</h2>
        <div className="p-div-ImagePerson">
          <div className="p-upload-section">
            <div>
              <div className="form-input-file-ImagePerson file">
                <label className="btn-upload-ImagePerson">
                  <input
                    type="file"
                    name="selfie"
                    id={0}
                    onChange={(e) => handleFile(e)}
                  />
                  <CloudUploadIcon />
                </label>
                <span className="file-preview">
                  <p className="p-file p-file-title">
                    {files[0]
                      ? `${files[0].name.substring(0, 15)}...`
                      : "Fotografia Selfie"}
                  </p>
                </span>
              </div>
              <div className="error-file">
                {errors && errors[0] ? (
                  <small className="p-error-ImagePerson">{errors[0]}</small>
                ) : null}
              </div>
            </div>

            <div>
              <div className="form-input-file-ImagePerson file">
                <label className="btn-upload-ImagePerson">
                  <input
                    type="file"
                    name="rutF"
                    id={1}
                    onChange={(e) => handleFile(e)}
                  />
                  <CloudUploadIcon />
                </label>
                <span className="file-preview">
                  <p className="p-file p-file-title">
                    {files[1]
                      ? `${files[1].name.substring(0, 15)}...`
                      : "Documento de identidad frontal"}
                  </p>
                </span>
              </div>

              <div className="error-file">
                {errors && errors[1] ? (
                  <small className="p-error-ImagePerson">{errors[1]}</small>
                ) : null}
              </div>
            </div>

            <div>
              <div className="form-input-file-ImagePerson file">
                <label className="btn-upload-ImagePerson">
                  <input
                    type="file"
                    name="rutB"
                    id={2}
                    onChange={(e) => handleFile(e)}
                  />
                  <CloudUploadIcon />
                </label>
                <span className="file-preview">
                  <p className="p-file p-file-title">
                    {files[2]
                      ? `${files[2].name.substring(0, 15)}...`
                      : "Documento de identidad posterior"}
                  </p>
                </span>
              </div>
              <div className="error-file">
                {errors && errors[2] ? (
                  <small className="p-error-ImagePerson">{errors[2]}</small>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="btn-section-ImagePerson">
          <Button className="btn-ImagePerson-Volver" onClick={stepBack}>
            Volver
          </Button>
          <Button className="btn-ImagePerson-Crear" onClick={imageVerify}>
            Crear
          </Button>
        </div>
      </div>
      <div className="Dialog-Section">
        <Dialog
          open={errorUpload}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Lo sentimos, hubo un error en la carga de imágenes.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="boton_block"
              onClick={() => setErrorUpload(false)}
            >
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
};
export default ImagePerson;
